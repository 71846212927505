import DOMPurify from 'dompurify'
import appConfig from '../../../../src/app-config'

/**
 * Sanitize and validate html string, handling edge cases and preventing XSS attacks.
 * @param {string} htmlContent - The input html to sanitize.
 * @returns {string} - A sanitized and validated html content, or an empty string if invalid.
 */
export const sanitizeHtml = (htmlContent) => {
  return DOMPurify.sanitize(htmlContent, {
    ADD_TAGS: appConfig.sanitizeHtmlAddTags,
    ADD_ATTR: appConfig.sanitizeHtmlAddAtts,
    CUSTOM_ELEMENT_HANDLING: {
      tagNameCheck: () => true,
      attributeNameCheck: () => true,
      allowCustomizedBuiltInElements: true, // customized built-ins are allowed
    },
  })
}

export const DEFAULT_URL = 'about:blank'

/**
 * Sanitize and validate a URL, handling edge cases and preventing XSS attacks.
 * @param {string} url - The input URL to sanitize.
 * @returns {string} - A sanitized and validated URL, or an empty string if invalid.
 */
export const sanitizeUrl = (url) => {
  try {
    if (typeof url !== 'string') {
      return url
    }

    const sanitizedAnchor = sanitizeHtml(`<a href="${url}"></a>`)

    const div = document.createElement('div')
    div.innerHTML = sanitizedAnchor

    return div.getElementsByTagName('a')[0].href || DEFAULT_URL
  } catch (e) {
    return url
  }
}
