/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import Editor from 'react-froala-wysiwyg'
import FroalaEditor from 'froala-editor'
import { withTheme } from 'styled-components'
import { get } from 'lodash'
import {
  notification,
  LanguageContext,
  EduIf,
  SpinLoader,
} from '@edulastic/common'
import { aws, math, appLanguages } from '@edulastic/constants'
import { withMathFormula } from '../HOC/withMathFormula'
import 'froala-editor/js/plugins.pkgd.min'
import 'froala-editor/css/plugins.pkgd.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
// froala.min.css is loaded at index as it required for preview as well.

import { isGcpsDistrictSelector } from './../../../../src/client/author/src/selectors/user'

import {
  uploadToS3,
  reIndexResponses,
  canInsert,
  beforeUpload,
  isValidUpdate,
} from '../helpers'
import headings from './FroalaPlugins/headings'
import customPastePlugin from './FroalaPlugins/customPastePlugin'
import customPlugin from './FroalaPlugins/customPlugin'
import imageUploadPlugin from './FroalaPlugins/imageUploadPlugin'
import useStickyToolbar from './FroalaPlugins/useStickyToolbar'
import {
  getToolbarButtons,
  getSpecialCharacterSets,
  isContainsMathContent,
} from './FroalaPlugins/helpers'
import { buttonWidthMap } from './FroalaPlugins/constants'
import {
  NoneDiv,
  ToolbarContainer,
  BackgroundStyleWrapper,
  StyledFroralaFooter,
} from './FroalaPlugins/styled'

import MathModal from './MathModal'

import {
  getMathHtml,
  replaceLatexesWithMathHtml,
  replaceMathHtmlWithLatexes,
} from '../utils/mathUtils'
import AudioPluginContainer from './FroalaPlugins/AudioPluginContainer'
import useTranscribeService from '../../../../src/client/common/customHooks/useTranscribeService'
import { sanitizeHtml } from '../utils/html'

const symbols = ['all']
const { defaultNumberPad } = math

// adds h1 & h2 buttons commands to froala editor.
headings(FroalaEditor)
// adds past event handler
customPastePlugin(FroalaEditor)
// register custom buttons
customPlugin(FroalaEditor)
// adds image.beforeUpload and image.inserted event handler
imageUploadPlugin(FroalaEditor)

const CustomEditor = ({
  value,
  onChange,
  toolbarId,
  toolbarSize,
  additionalToolbarOptions,
  initOnClick,
  theme,
  border,
  centerContent,
  imageDefaultWidth,
  videoDefaultWidth,
  placeholder,
  fontSize,
  className,
  buttons,
  advancedAreOpen,
  customCharacters,
  editorHeight,
  allowQuickInsert = true,
  unsetMaxWidth = false,
  isPremiumUser,
  isSpeechToTextEnabled,
  setIsSTTActive,
  onSTTError,
  isGcpsDistrict,
  footer,
  ...restOptions
}) => {
  const mathFieldRef = useRef(null)
  const editorRef = useRef(null)
  const toolbarContainerRef = useRef(null)
  const isSTTActiveRef = useRef(false)
  const [showMathModal, setMathModal] = useState(false)
  const [mathModalIsEditable, setMathModalIsEditable] = useState(true)
  const [currentLatex, setCurrentLatex] = useState('')
  const [currentMathEl, setCurrentMathEl] = useState(null)
  const [content, setContent] = useState('')
  const [prevValue, setPrevValue] = useState('')
  const [configState, setConfigState] = useState(null)
  const [mathField, setMathField] = useState(null)
  const [audioElement, setAudioElement] = useState(null)
  const { currentLanguage } = useContext(LanguageContext)
  const EditorRef = useRef(null)

  useStickyToolbar(toolbarId, EditorRef.current, toolbarContainerRef.current)

  const onInitSTTCallback = () => {
    EditorRef.current?.events?.trigger?.('speechToText.initiateSpeechToText')
  }

  const onStopSTT = ({ error }) => {
    $("[data-cmd='initiateSpeechToText']").removeClass('fr-active')
  }

  const onSTTTextUpdate = (text, isFinal) => {
    if (isFinal) {
      EditorRef.current?.events?.trigger?.('speechToText.removePartialText')
      EditorRef.current.html.insert(`${text}`)
    } else {
      EditorRef.current?.events?.trigger?.('speechToText.removePartialText')
      // adding partial processed text from aws transcribe and later removing
      EditorRef.current.html.insert(
        `<span class="fr-partial-stt">${text}...</span>`
      )
    }
  }

  const onSTTActiveCallback = () => {
    // Focus in froala when transcribe socket connection is active
    $(
      `#${toolbarContainerRef.current?.id} button[data-cmd='initiateSpeechToText']`
    ).addClass('fr-active')
    EditorRef.current?.events?.focus(true)
  }

  const onSTTButtonRefresh = () => {
    if (isSTTActiveRef.current) {
      $(
        `#${toolbarContainerRef.current?.id} button[data-cmd='initiateSpeechToText']`
      ).addClass('fr-active')
    }
  }

  const {
    isLoading: isLoadingSTT,
    onStart: onStartSTT,
    isActive: isSTTActive,
  } = useTranscribeService({
    isEnabled: isSpeechToTextEnabled,
    onTextUpdateCallback: onSTTTextUpdate,
    onErrorCallback: onSTTError,
    onStopCallback: onStopSTT,
    onInitCallback: onInitSTTCallback,
    onActiveCallback: onSTTActiveCallback,
  })

  useEffect(() => {
    isSTTActiveRef.current = isSTTActive
    setIsSTTActive(isSTTActive)
  }, [isSTTActive])

  const toolbarButtons = getToolbarButtons(
    'STD',
    toolbarSize,
    additionalToolbarOptions,
    buttons,
    null,
    isPremiumUser
  )

  const toolbarButtonsMD = getToolbarButtons(
    'MD',
    toolbarSize,
    additionalToolbarOptions,
    buttons,
    null,
    isPremiumUser
  )
  const toolbarButtonsSM = getToolbarButtons(
    'SM',
    toolbarSize,
    additionalToolbarOptions,
    buttons,
    null,
    isPremiumUser
  )
  const toolbarButtonsXS = getToolbarButtons(
    'XS',
    toolbarSize,
    additionalToolbarOptions,
    buttons,
    null,
    isPremiumUser
  )
  const specialCharactersSets = getSpecialCharacterSets(customCharacters)
  const initialConfig = Object.assign(
    {
      key: process.env.REACT_APP_FROALA_KEY,
      imageInsertButtons: ['imageUpload'], // hide other image uplaod options
      imageDefaultDisplay: 'inline',
      linkAlwaysBlank: true, // adding to make link always open in blank
      zIndex: 996, // header 999 | dropdown 998 | froala calculate toolbar zIndex - 1
      imageDefaultWidth,
      initOnClick,
      toolbarButtons,
      toolbarButtonsMD,
      toolbarButtonsSM,
      toolbarButtonsXS,
      videoInsertButtons: [
        'videoBack',
        '|',
        'videoByURL',
        'videoEmbed',
        'videoUpload',
      ],
      videoResize: true,
      videoMove: true,
      videoDefaultAlign: 'left',
      videoDefaultWidth: 480,
      videoDefaultDisplay: 'inline',
      tableResizerOffset: 10,
      tableResizingLimit: 50,
      toolbarInline: true,
      toolbarVisibleWithoutSelection: true,
      toolbarContainer: toolbarId
        ? `#froalaToolbarContainer-${toolbarId}`
        : undefined,
      placeholderText: placeholder,
      htmlAllowedEmptyTags: [
        'textarea',
        'a',
        'iframe',
        'object',
        'video',
        'style',
        'script',
        '.fa',
        'span',
        'path',
        'line',
        'textinput',
        'textdropdown',
        'mathinput',
        'mathunit',
        'paragraphnumber',
        'response',
        'specialCharacters',
      ],
      specialCharactersSets,
      fontSize: ['12', '14', '16', '20'],
      fontSizeDefaultSelection: '14',
      htmlAllowedTags: ['.*'],
      htmlAllowedAttrs: ['.*'],
      htmlRemoveTags: ['script'],
      quickInsertEnabled: allowQuickInsert,
      events: {
        click: function (evt) {
          const closestMathParent = evt.currentTarget.closest(
            'span.input__math'
          )
          const paraRemove = evt.currentTarget.closest(
            'span.paragraph-number-remove'
          )
          if (closestMathParent) {
            this.selection.save()
            setCurrentLatex(closestMathParent.getAttribute('data-latex'))
            const mqeditable = closestMathParent.getAttribute('mqeditable')
            setMathModalIsEditable(mqeditable !== 'false')
            setCurrentMathEl(closestMathParent)
            setMathModal(true)
          } else if (paraRemove) {
            paraRemove.parentElement.remove()
            this.selection.save()
            const updatedHtml = reIndexResponses(this.html.get(true))
            if (updatedHtml) {
              this.html.set(updatedHtml)
            }
          } else {
            setCurrentLatex('')
            setCurrentMathEl(null)
          }
        },
        mouseup: function () {
          const range = this.selection.ranges()[0]
          const {
            endContainer,
            startContainer,
            commonAncestorContainer,
          } = range
          if (
            !this.selection.isCollapsed() &&
            (isContainsMathContent(endContainer) ||
              isContainsMathContent(startContainer) ||
              isContainsMathContent(commonAncestorContainer))
          ) {
            // disable font size button
            $("[data-cmd='fontSize']").addClass('fr-disabled')
          } else {
            $("[data-cmd='fontSize']").removeClass('fr-disabled')
          }
        },
        keydown: function (evt) {
          if (evt.which === 8) {
            const range = this.selection.ranges()[0]
            const parent = range.commonAncestorContainer
            const cursorEl = parent.childNodes[range.startOffset - 1]
            if (parent && range.startOffset === range.endOffset) {
              if (!$(cursorEl).length || !cursorEl || !cursorEl.tagName) return
              if (
                [
                  'RESPONSE',
                  'TEXTINPUT',
                  'TEXTDROPDOWN',
                  'MATHINPUT',
                  'PARAGRAPHNUMBER',
                  'MATHUNIT',
                ].includes(cursorEl.tagName)
              ) {
                cursorEl.remove()
                this.selection.save()
                const updatedHtml = reIndexResponses(this.html.get(true))
                if (updatedHtml) {
                  this.html.set(updatedHtml)
                }
                return
              }
              if (
                cursorEl.tagName === 'AUDIO' ||
                (cursorEl.tagName === 'SPAN' &&
                  $(cursorEl).hasClass('input__math') &&
                  $(cursorEl).attr('data-latex'))
              ) {
                cursorEl.remove()
                return
              }
              return
            }
            if (
              cursorEl &&
              cursorEl.tagName === 'SPAN' &&
              $(cursorEl).hasClass('input__math') &&
              $(cursorEl).attr('data-latex')
            ) {
              cursorEl.remove()
              return
            }
          }
        },
        'initiate.speechToText': function (evt) {
          if (isSpeechToTextEnabled) {
            onStartSTT()
          }
        },
        'speechToText.initiateSpeechToText': function (evt) {
          const existingContentLength = this?.$el?.text()?.length
          // Place cursor to end
          if (existingContentLength > 0) {
            this?.selection?.setAtEnd?.(
              this?.$el?.get?.(0),
              existingContentLength
            )
            this?.selection?.restore?.()
          }
        },
        'speechToText.removePartialText': function (evt) {
          this?.selection?.save()
          const editorContent = this.html.get(true)
          const tempContainer = document.createElement('span')
          tempContainer.innerHTML = sanitizeHtml(editorContent)
          const spanToRemove = tempContainer.querySelector('.fr-partial-stt')
          if (spanToRemove) {
            spanToRemove.parentNode.removeChild(spanToRemove)
            this.html.set(tempContainer.innerHTML)
          }
        },
        'speechToText.buttonRefresh': function (evt) {
          onSTTButtonRefresh()
        },
        'audio.insert': function (audio) {
          setAudioElement(audio)
        },
        'video.beforeUpload': function (video) {
          if (
            !canInsert(this.selection.element()) ||
            !canInsert(this.selection.endElement()) ||
            !beforeUpload(video[0], 'video')
          ) {
            return false
          }
          this.video.showProgressBar()
          uploadToS3(video[0], aws.s3Folders.DEFAULT)
            .then((url) => {
              const embedded = `<video class="fr-draggable" src='${url}' controls>Video is not supported on this browser.</video>`
              this.video.insert(embedded)
            })
            .catch((e) => {
              console.error(e)
              this.popups.hideAll()
              notification({ messageKey: 'videoUploadErr' })
            })
          return false
        },
        'video.linkError': function (link) {
          const popup = this.popups.areVisible()
          const layer = popup?.find('.fr-video-progress-bar-layer')
          layer
            ?.find('.fr-message')
            ?.text(
              'The video cannot be added because the address is invalid/unsupported.'
            )
        },
        'video.codeError': function (code) {
          const popup = this.popups.areVisible()
          const layer = popup?.find('.fr-video-progress-bar-layer')
          layer
            ?.find('.fr-message')
            ?.text(
              'The video cannot be added because the embed code is invalid/unsupported.'
            )
        },
        'edit.on': function (e, editor) {
          if (restOptions.readOnly === true) {
            this.edit.off()
            this.$el?.find('.input__math')?.css('pointer-events', 'none')
            this.$el?.find('img')?.css('pointer-events', 'none')
            this.$el?.find('video')?.css('pointer-events', 'none')
          }
        },
        'toolbar.show': function () {
          /**
           * there are no option to change tooltip of toolbar buttons
           * And 'tooltips:flase' option does not work properly on windows and linux
           * So just used this way for now.
           * @see https://snapwiz.atlassian.net/browse/EV-12857
           */
          $('[data-cmd="moreText"]')?.prop('title', 'More Tools')
        },
        'toolbar.hide': function () {
          if (this.hasFocus) {
            return false
          } else {
            return true
          }
        },
        initialized: function () {
          this.hasFocus = false
        },
        focus: function () {
          if (initOnClick) {
            this.hasFocus = true
          }
          if (this.hasFocus && typeof this.handleStickyToolbar === 'function') {
            this.handleStickyToolbar(this, toolbarContainerRef.current)
          }
        },
        blur: function () {
          if (initOnClick) {
            this.hasFocus = false
            if (this.toolbar) {
              this.toolbar.hide()
            }
          }
          if (isSpeechToTextEnabled) {
            onStartSTT({ isBlurEvent: true })
          }
        },
        'file.beforeUpload': function (files = []) {
          const file = files[0]

          if (!file) {
            this.popups.hideAll()
            notification({ messageKey: 'fileUploadErr' })
            return false
          }

          // currently supporting only pdf through file upload
          if (file.type !== 'application/pdf') {
            this.popups.hideAll()
            notification({ messageKey: 'fileTypeErr' })
            return false
          }

          uploadToS3(file, aws.s3Folders.DEFAULT)
            .then((url) => {
              this.file.insert(url, file.name)
            })
            .catch((e) => {
              console.error(e)
              this.popups.hideAll()
              notification({ messageKey: 'fileUploadErr' })
            })
          return false
        },
        'commands.after': function (cmd) {
          if (cmd === 'moreText') {
            this.toolbarExpanded = !this.toolbarExpanded
            return
          }
          if (
            cmd === 'textinput' ||
            cmd === 'textdropdown' ||
            cmd === 'mathinput' ||
            cmd === 'mathunit' ||
            cmd === 'response' ||
            cmd === 'paragraphNumber'
          ) {
            this.selection.save()
            const updatedHtml = reIndexResponses(this.html.get(true))
            if (updatedHtml) {
              this.html.set(updatedHtml)
            }
          }
        },
      },
    },
    restOptions
  )

  // Math Html related helper functions

  const initMathField = () => {
    if (mathField || !window.MathQuill) return
    if (mathFieldRef.current) {
      const MQ = window.MathQuill.getInterface(2)
      try {
        setMathField(MQ.StaticMath(mathFieldRef.current))
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }

  const setChange = (val) => {
    setContent(val)

    const valueToSave = replaceMathHtmlWithLatexes(val)
    setPrevValue(valueToSave)

    onChange(valueToSave)
  }

  // Math Modal related functions
  const saveMathModal = (latex) => {
    if (!latex) {
      // close the modal and return back if nothing was entered
      setMathModal(false)
      EditorRef.current.selection.restore() // set cursor at the end of content
      return
    }

    EditorRef.current.selection.restore()
    const mathHtml = getMathHtml(latex)
    if (currentMathEl) {
      currentMathEl.innerHTML = sanitizeHtml(mathHtml)
      currentMathEl.setAttribute('data-latex', latex)
    } else {
      EditorRef.current.html.insert(
        `<span class="input__math" contenteditable="false" data-latex="${latex}">${mathHtml}</span> `
      )
    }

    // if html is inserted over using editor methods `saveStep` requires to be called
    // to update teh editor. Otherwise `modalChange` wont be triggered!
    EditorRef.current.undo.saveStep()

    setMathModal(false)
  }

  const closeMathModal = () => setMathModal(false)

  // Froala configuration
  const manualControl = ({ getEditor, initialize }) => {
    initialize()
    EditorRef.current = getEditor()
  }

  const hasResponseBoxBtn = () =>
    additionalToolbarOptions.includes('textinput') ||
    additionalToolbarOptions.includes('response') ||
    additionalToolbarOptions.includes('mathinput') ||
    additionalToolbarOptions.includes('mathunit') ||
    additionalToolbarOptions.includes('textdropdown') ||
    additionalToolbarOptions.includes('responseBoxes') ||
    additionalToolbarOptions.includes('paragraphNumber')

  useEffect(() => {
    let toolbarWidth = toolbarContainerRef?.current?.clientWidth
    // if response button is there than subtracting the width of response button
    if (hasResponseBoxBtn()) {
      for (let i = 0; i < additionalToolbarOptions.length; i++) {
        if (i === 3) break
        toolbarWidth -= buttonWidthMap[additionalToolbarOptions[i]]
      }
    }
    /**
     * calculating the toolbar button counts dynamically that can be displayed without moreText and the rest will be displayed
     * in the moreText. Here each button takes the width of 42px and padding of total 31px is given to the right and left of the toolbar container
     * so subtracting the total padding and dividing the remaining width by each button width to get the count of buttons.
     */

    let buttonCounts = Math.floor((toolbarWidth - 31) / 42)

    if (
      initialConfig.toolbarButtons?.moreText?.buttons?.length > buttonCounts
    ) {
      buttonCounts -= 1
    }
    const _toolbarButtons = getToolbarButtons(
      'STD',
      toolbarSize,
      additionalToolbarOptions,
      buttons,
      buttonCounts,
      isPremiumUser
    )
    const _toolbarButtonsMD = getToolbarButtons(
      'MD',
      toolbarSize,
      additionalToolbarOptions,
      buttons,
      buttonCounts,
      isPremiumUser
    )
    const _toolbarButtonsSM = getToolbarButtons(
      'SM',
      toolbarSize,
      additionalToolbarOptions,
      buttons,
      buttonCounts,
      isPremiumUser
    )
    const _toolbarButtonsXS = getToolbarButtons(
      'XS',
      toolbarSize,
      additionalToolbarOptions,
      buttons,
      buttonCounts,
      isPremiumUser
    )

    const updatedConfig = {
      ...initialConfig,
      toolbarButtons: _toolbarButtons,
      toolbarButtonsMD: _toolbarButtonsMD,
      toolbarButtonsSM: _toolbarButtonsSM,
      toolbarButtonsXS: _toolbarButtonsXS,
      // disable word paste plugin for GCPS
      wordPasteModal: !isGcpsDistrict,
      wordPasteKeepFormatting: !isGcpsDistrict,
    }

    // for hidden refs wait for it to be shown in the dom to set config.
    if (EditorRef?.current?.offsetParent === null) {
      setConfigState(null)
    } else {
      setConfigState(updatedConfig)
    }
  }, [toolbarContainerRef?.current, advancedAreOpen, isGcpsDistrict])

  useEffect(() => {
    // sample extension of custom buttons
    initMathField()
    if (value && hasResponseBoxBtn()) {
      setChange(reIndexResponses(value))
    }
    // Math Input
    FroalaEditor.DefineIcon('math', { NAME: 'math', template: 'math' })
    FroalaEditor.RegisterCommand('math', {
      title: 'Math',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback() {
        EditorRef.current = this
        if (
          !canInsert(this.selection.element()) ||
          !canInsert(this.selection.endElement())
        )
          return false
        this.selection.save()
        setCurrentLatex('')
        setCurrentMathEl(null)
        setMathModal(true)
        this.undo.saveStep()
      },
    })
  }, [])

  useEffect(() => {
    if (mathFieldRef.current) {
      initMathField()
    }
  }, [mathFieldRef.current])

  useEffect(() => {
    // In case of prop updates after onChange, we are gonna ignore that.
    if (!value) {
      setContent('')
      setPrevValue('')
      return
    }

    if (prevValue === value) {
      return
    }
    setPrevValue(value)
    setContent(replaceLatexesWithMathHtml(value))
  }, [value])

  useEffect(() => {
    if (
      value &&
      content &&
      currentLanguage &&
      currentLanguage !== appLanguages.LANGUAGE_EN &&
      hasResponseBoxBtn() &&
      !isValidUpdate(value, content)
    ) {
      // in spanish mode, if they add/remove responseboxes
      // use previous content instead of updated
      setChange(value)
    }
  }, [content])

  useEffect(() => {
    if (editorRef.current && editorRef.current.editor) {
      editorRef.current.editor.opts.placeholderText = placeholder
      editorRef.current.editor.placeholder.refresh()
    }
  }, [placeholder])

  return (
    <>
      {audioElement &&
        createPortal(
          <AudioPluginContainer EditorRef={EditorRef} />,
          audioElement
        )}
      <EduIf condition={isLoadingSTT}>
        <SpinLoader />
      </EduIf>
      <MathModal
        isEditable={mathModalIsEditable}
        show={showMathModal}
        symbols={symbols}
        numberPad={defaultNumberPad}
        showDropdown={false}
        showResposnse={false}
        value={currentLatex}
        onSave={saveMathModal}
        onClose={closeMathModal}
      />
      <BackgroundStyleWrapper
        backgroundColor={configState?.backgroundColor}
        centerContent={centerContent}
        border={border}
        haveFooter={!!footer}
        theme={theme}
        fontSize={fontSize}
        className={className}
        editorHeight={editorHeight}
        unsetMaxWidth={unsetMaxWidth}
      >
        {toolbarId && (
          <ToolbarContainer
            id={`froalaToolbarContainer-${toolbarId}`}
            ref={toolbarContainerRef}
            toolbarInline={initialConfig.toolbarInline}
          />
        )}
        {configState && (
          <Editor
            model={content}
            ref={editorRef}
            onModelChange={setChange}
            config={configState}
            onManualControllerReady={manualControl}
          />
        )}
        {footer && <StyledFroralaFooter>{footer}</StyledFroralaFooter>}
      </BackgroundStyleWrapper>
      <NoneDiv>
        <span ref={mathFieldRef} className="input__math__field" />
      </NoneDiv>
    </>
  )
}

CustomEditor.propTypes = {
  value: PropTypes.string.isRequired,
  toolbarId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  toolbarSize: PropTypes.oneOf(['STD', 'MD', 'SM', 'XS']),
  additionalToolbarOptions: PropTypes.array,
  customCharacters: PropTypes.array,
  readOnly: PropTypes.bool,
  imageDefaultWidth: PropTypes.number,
  videoDefaultWidth: PropTypes.number,
  initOnClick: PropTypes.bool,
  border: PropTypes.string,
  centerContent: PropTypes.bool,
  editorHeight: PropTypes.number,
  isSpeechToTextEnabled: PropTypes.bool.isRequired,
  setIsSTTActive: PropTypes.func,
  onSTTError: PropTypes.func,
}

CustomEditor.defaultProps = {
  toolbarId: null,
  initOnClick: true,
  toolbarSize: 'STD',
  customCharacters: [],
  additionalToolbarOptions: [],
  readOnly: false,
  imageDefaultWidth: 300,
  videoDefaultWidth: 480,
  border: 'none',
  centerContent: false,
  editorHeight: null,
  setIsSTTActive: () => {},
  onSTTError: () => {},
}

const enhance = compose(
  withMathFormula,
  withTheme,
  connect((state) => ({
    isGcpsDistrict: isGcpsDistrictSelector(state),
    isPremiumUser: get(state, ['user', 'user', 'features', 'premium'], false),
    advancedAreOpen: state?.assessmentplayerQuestions?.advancedAreOpen,
  }))
)

export default enhance(CustomEditor)
